import Button from 'components/Buttons/Button'
import React, { useContext } from 'react'
import { SupportContext } from 'utils/context'
import QuestionIcon from 'utils/icons/Question'

const HelpCircleButton = ({slug}) => {

  const { setSupportWithField } = useContext(SupportContext)

  const supportClick = e => {
    e.preventDefault()
    setSupportWithField(slug)
  }

  return (
    <Button className='help-circle-btn' onClick={supportClick}><QuestionIcon className='help-circle-icon'/></Button>
  )
}

export default HelpCircleButton
