import { useContext } from 'react'
import TenantStr from 'components/TenantStr'
import SignupForm from 'components/SignupForm'
import TermsConditions from 'components/TermsConditions'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import Tile from 'components/Tile'
import WarningIcon from 'utils/icons/Warning'

const Signup = () => {
  const { t } = useTranslation()
  const { tenant, showMaintenance } = useContext(AppContext)

  const isPc = tenant && tenant.slug === 'cis'
 
  return (
    <div className="SitePage Signup">
      <div className="container">
        <h2>
          <Trans
            i18nKey="signUpMsg"
            components={{ productName: <TenantStr ProductName />}}
          />
        </h2>
        <p>
          <Trans
            i18nKey="signingUpIsSimpleMsg"
            components={{ productName: <TenantStr ProductName /> }}
          />
        </p>
        <p>{t("Once you've signed up, check your inbox for an activation confirmation email.")}</p>
        <div className="TileBody">
          {showMaintenance ? (
            <>
              <p>{t('Signup is currently disabled')}</p>
              <Tile className="under-maintenance-warning" Warning Icon={WarningIcon} IconName="WarningIcon" SmallIcon>
                <small>
                  {t('Please be aware that we are performing routine maintenance on the site. Please try again later.')}
                </small>
              </Tile>
            </>
          ) : <SignupForm />}
        </div>
        {isPc && <TermsConditions Signup />}
      </div>
    </div>
  )
}

export default Signup
