import { useContext } from 'react'
import Divider from 'components/Divider'
import LoadingTiles from 'components/LoadingTiles'
import AutoPolicies from 'account/AutoPolicies'
import HabPolicies from 'account/HabPolicies'
import { AccountContext, AppContext } from 'utils/context' 
import { useTranslation } from 'react-i18next'

const AllPolicies = () => {

  const { t } = useTranslation()

  let { driversLoading, vehiclesLoading, locationsLoading } = useContext(AccountContext)
  const { tenant } = useContext(AppContext)
      
  if (!tenant.dashboard_show_get_quote_link && !HabPolicies() && !AutoPolicies()) {
    return(
      <div className="NoPolicies">
        <strong>{t("No active policies found.")}</strong>
      </div>
    )
  }

  return (
    <div className="AllPolicies">
      {driversLoading || vehiclesLoading ? (
        <LoadingTiles />
      ) : (
        <AutoPolicies />
      )}
      {locationsLoading ? (
        <>
          <Divider Small />
          <LoadingTiles />
        </>  
      ) : (
        <HabPolicies />
      )}
    </div>
  )
}

export default AllPolicies