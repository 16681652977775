import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import { FormContext } from 'utils/context'
import { confirmDate, formatCurrency, formatNumber, titleCase } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { conditionChoices } from 'utils/vars'

const VehicleInformationConfirm = ({ province, QuoteOnly }) => {

  const { t } = useTranslation()
  const { data } = useContext(FormContext)

  const showKms = data['vehicle_condition'] !== 'used' 

  const getCondition = (condition) => {
    const key = conditionChoices.findIndex(innerArray => innerArray.includes(condition));
    return conditionChoices[key][1]
  }

  return (
    <ConfirmCard title={t("Vehicle Information")} slug="vehicle-details" id={data.id}>
      <ul>
        {!QuoteOnly && (
          <>
            <li>
              <small>{t('Purchase Date')}</small>
              {confirmDate(data['purchase_date'])}
            </li>
            <li>
              <small>{t('Registered Province')}</small>
              {province}
            </li>
          </>
        )}
        <li>
          <small>{t('Vehicle Price')}</small>
          {formatCurrency(data['price_of_vehicle'], '$0,0')}
        </li>
        <li>
          <small>{t('Vehicle Condition')}</small>
          {titleCase(getCondition(data['vehicle_condition']))}
        </li>
        {showKms &&
          <li>
            <small>{t('KMs at Purchase')}</small>
            {formatNumber(data['kms_at_purchase'], '0,0')}km
          </li>
        }
      </ul>
    </ConfirmCard>
  )
}

export default VehicleInformationConfirm